import React from 'react';
import type { Hit } from 'instantsearch.js';
import { Highlight } from 'react-instantsearch-hooks-web';
import { Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid } from '@mui/material';

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const styles = {
  card: {
    // Provide some spacing between cards
    margin: 16,

    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    display: "flex",
    flexDirection: "column",

    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    justifyContent: "space-between"
  }
};


export function Hit({ hit }: HitProps) {

  const [hideImage, setHideImage] = React.useState<boolean>(false);
    return (
      <Grid item style={{display: 'flex'}} sm={4} xs={12}>
        <Card style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%'}}>
          <CardHeader title={<Highlight attribute="title" hit={hit} />} subheader={<Highlight attribute="author" hit={hit} />}/>
          {!hideImage && <CardMedia
            component="img"
            image={`https://covers.spartacusbooks.net/${hit.risbn}.jpg`}
            onError={ev => {
              setHideImage(true);
            }}
          />}
          <CardContent>

          </CardContent>
          <CardActions>
            <Chip label={priceFormatter.format(hit.price)} />
            <Chip label={hit.cat} variant="outlined" />
            <Chip label={hit.cat2.split('>')[1].trim()} variant="outlined" />
          </CardActions>
        </Card>
      </Grid>
    );
  }
  type HitProps = {
    hit: Hit;
  };
