import { Grid } from '@mui/material';
import React from 'react';
import { useHits } from 'react-instantsearch-hooks-web';
import { Hit } from './Hit';

export function CustomHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <Grid container spacing={2} alignItems="stretch">
      {hits.map(hit => (<Hit hit={hit} />))}
    </Grid>
  );

}
