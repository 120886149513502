import React, { useEffect } from 'react';
import { useConnector } from 'react-instantsearch-hooks-web';
import connectRange from 'instantsearch.js/es/connectors/range/connectRange';
import { Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';


export function useRangeSlider(props) {
  return useConnector(connectRange, props);
}

export function RangeSlider(props) {
  const {
    start,
    range,
    canRefine,
    refine,
    sendEvent,
  } = useRangeSlider(props);

  const [value, setValue] = React.useState<number[]>(start);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    refine(value);
  }, [value]);

  if(!canRefine){
    return <></>;
  }

  return (<Box pr={5}>
      <Typography gutterBottom>Price</Typography>
      <Slider
      getAriaLabel={() => 'Price slider'}
      value={value}
      min={range.min}
      max={range.max}
      onChange={handleChange}
      valueLabelDisplay="auto"
    />
  </Box>);
}
