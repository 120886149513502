import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  DynamicWidgets,
  RefinementList,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  SearchBox,
  HierarchicalMenu,
  RangeInput
} from 'react-instantsearch-hooks-web';

import './App.css';
import { CustomHits } from './CustomHits';
import { RangeSlider } from './RangeSlider';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

const searchClient = algoliasearch(
  'AWU52GU09L',
  'd6e0cb300fb763d91817870592ec9603'
);

export function App() {
  return (
    <Container>
      <Box pt={5}>
        <Grid container spacing={2}>
          <InstantSearch searchClient={searchClient} indexName="inventory">
            <Configure hitsPerPage={36} />
            <div className="search-panel">
            <Grid item xs={12} sm={3}>

              <div className="search-panel__filters">
              <DynamicWidgets>
                <RangeSlider attribute="price" />
                <HierarchicalMenu
                  attributes={['cat', 'cat2']}
                  limit={200}
                />
              </DynamicWidgets>
              </div>
            </Grid>

            <Grid item xs={12} sm={9}>
              <div className="search-panel__results">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SearchBox placeholder="" className="searchbox" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomHits />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="pagination">
                      <Pagination />
                    </div>
                  </Grid>
                </Grid>
              </div>
              </Grid>
            </div>
          </InstantSearch>
        </Grid>
      </Box>
    </Container>
  );
}
